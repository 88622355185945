import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"
const NetworkCabling = ({ data }) => (
  <Layout>
    <Seo
      title="Network Cabling"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Network Cabling</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <h4>Network Cabling</h4>
            <p>
              The performance and functionality of the communications is
              contingent upon the quality and design of the network that
              transports the data. We provide a full line of quality and
              performance cabling services to accommodate all of your
              communication and network needs. Our expert designers and
              installers have the ability and experience you need to design,
              install, maintenance and expand cable infrastructures.
            </p>
            <ul>
              <li>Structured Cabling</li>
              <li>Camera/Video Cabling</li>
              <li>Audio/Speakers</li>
              <li>Data Networks</li>
              <li>Voice Networks</li>
              <li>Fiber</li>
            </ul>
            <h4>Network Infrastructure</h4>
            <p>
              Every site requires a targeted solution regarding the network
              components and environment in which the network equipment will
              exist and perform. Our services encompass designing network and
              data rooms to individual small network co-locations within an
              office environment.
            </p>
            <p>
              We work with the best manufacturers in the industry to accommodate
              equipment and peripheral system needs. See us for all of your
              Voice, Data, Network, Audio, Video and signal control systems.
            </p>
            <ul>
              <li>Server & Data Racks</li>
              <li>Equipment Racks</li>
              <li>Network Firewalls/Switches/Wireless Access Points</li>
              <li>DVR/NVR Systems</li>
              <li>Cameras</li>
              <li>Speakers</li>
              <li>VoIP/Phones</li>
            </ul>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NetworkCabling

export const NetworkCablingQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
